var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"mb-2 text-h4",staticStyle:{"word-break":"keep-all"}},[_vm._v(" Mes groupes ")]),_c('v-card-subtitle',[_vm._v(" Cette page regroupe les groupes auquel vous appartenez. ")]),(_vm.groups)?_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.groups,"items-per-page":-1,"footer-props":{
          'items-per-page-options': [5, 10, 20, 30],
          itemsPerPageText: 'Elts par page:',
        }},scopedSlots:_vm._u([{key:"item.level",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{staticClass:"mr-4",attrs:{"color":(item.level == 'admin' && 'red') || 'teal',"label":"","text-color":"white","small":""}},[_vm._v(" "+_vm._s({ admin: "Admin", member: "Member", observer: "Observateur" }[ item.level ])+" ")])]}},{key:"item.edit",fn:function(ref){
        var item = ref.item;
return [(item.level == 'admin')?_c('v-btn',{attrs:{"color":"blue","dark":"","icon":""},on:{"click":function($event){return _vm.showGroupMembers(item.group)}}},[_c('v-icon',[_vm._v("edit")])],1):_vm._e()]}}],null,true)})],1):_c('v-progress-circular',{staticClass:"d-block mx-auto",attrs:{"indeterminate":""}})],1),_c('GroupMembers',{attrs:{"group_id":_vm.dialog_group},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }