<template>
  <div>
    <v-card flat>
      <v-card-title
        class="mb-2 text-h4"
        style="word-break: keep-all"
      >
        Mes groupes
      </v-card-title>
      <v-card-subtitle>
        Cette page regroupe les groupes auquel vous appartenez.
      </v-card-subtitle>

      <v-card-text v-if="groups">
        <v-data-table
          :headers="headers"
          :items="groups"
          :items-per-page="-1"
          :footer-props="{
            'items-per-page-options': [5, 10, 20, 30],
            itemsPerPageText: 'Elts par page:',
          }"
        >
          <template v-slot:[`item.level`]="{ item }">
            <v-chip
              :color="(item.level == 'admin' && 'red') || 'teal'"
              class="mr-4"
              label
              text-color="white"
              small
            >
              {{
                { admin: "Admin", member: "Member", observer: "Observateur" }[
                  item.level
                ]
              }}
            </v-chip>
          </template>
          <template v-slot:[`item.edit`]="{ item }">
            <v-btn
              v-if="item.level == 'admin'"
              color="blue"
              dark
              icon
              @click="showGroupMembers(item.group)"
            >
              <v-icon>edit</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
      <v-progress-circular
        v-else
        class="d-block mx-auto"
        indeterminate
      />
    </v-card>

    <GroupMembers
      v-model="dialog"
      :group_id="dialog_group"
    />
  </div>
</template>

<script>
import GroupMembers from "@/components/Groups/GroupMembers";

export default {
  components: { GroupMembers },
  data() {
    return {
      headers: [
        { text: "Nom", value: "group.name" },
        { text: "Description", value: "group.description" },
        { text: "Niveau", value: "level" },
        { text: "Edit", value: "edit" },
      ],
      groups: undefined,
      dialog: false,
      dialog_group: undefined,
    };
  },
  mounted() {
    // Get groups
    this.axiosPost(
      process.env.VUE_APP_API_URL + "/groups/getUserGroupsWithLevel"
    )
      .then((res) => {
        this.groups = res.data;
      })
      .catch((err) => {
        this.$alert.$emit("snackbar", {
          message: err.response.data,
          status: "error",
        });
      });
  },
  methods: {
    showGroupMembers(group) {
      this.dialog_group = group._id;
      this.dialog = true;
    },
  },
};
</script>

<style scoped>
.v-data-footer__select {
  margin-right: 0px;
}
</style>